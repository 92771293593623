html, 
body {
  height: 100%;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(max-width: 750px) {
    align-items: flex-start;
  }
}

:root {
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 32px;
  --space-xl: 64px;
}

.container {
  padding: 3em;
  box-sizing: border-box;
  width: 100%;
  max-width: 85vw;
  
  @media(max-width: 1100px) {
    max-width: 90vw;
  }
  
  @media(max-width: 750px) {
    padding: 2em;
    max-width: 95vw;
  }
  
  @media(max-width: 550px) {
    padding: 1.2em 1.8em;
    max-width: 100%;
  }
}

.heading {
  margin-bottom: var(--space-l);
  
  @media(max-width: 750px) {
    margin-bottom: var(--space-m);

  }
}

.type-xl {
  font-size: 54px;
  
  @media(max-width: 1100px) {
    font-size: 42px;
  }
  
  @media(max-width: 750px) {
    font-size: 36px;
  }
}

.type-m {
  font-size: 20px;
  
  @media(max-width: 1100px) {
    font-size: 18px;
  }
  
  @media(max-width: 750px) {
    font-size: 16px;
  }
}

p {
  max-width: 50em;
  line-height: 1.8;
  margin: 1em 0 1em 0;
  
  @media(max-width: 550px) {
    line-height: 1.7;
    margin: 0.8em 0 0.8em 0;
  }
}

h1 {
  font-weight: normal;
  margin: 0;
}

.mb-l {
  margin-bottom: var(--space-l);
}

.ml-m {
  margin-left: var(--space-m);
}

.ml-xl {
  margin-left: var(--space-xl);
}

.my-xl {
  margin: var(--space-xl) 0;
}

.divider {
  height: 1px;
  width: 100%;
  background: #e1e1e1;
  margin: var(--space-xl) 0;
  
  @media(max-width: 750px) {
    margin: var(--space-l) 0;
  }
}

.profiles {
  display: flex;
  align-items: flex-start;
  
  @media(max-width: 1100px) {
    flex-direction: column;
  }
}

.card {
  display: flex;
  align-items: center;
  font-size: 1.25vw;

  &:last-child {
    margin-left: 3em;
  }
}

.profile-image {
  height: 10vw;
  width: 10vw;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2.2em;

  .title {
    margin-bottom: 1.2em;
  }
}

.button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  border: 3px solid #000;
  border-radius: 5px;
  padding: 1em 1.2em;
  font-weight: 500;
  
  .external-link-icon {
    width: 1.5vw;
    height: 1.5vw;
    background: url('../images/external-link.svg') center center no-repeat;
    background-size: contain;
    margin-top: 0.2em;
  }
  
  &:hover {
    background-color: #000;
    color: #fff;
    
    .external-link-icon {
      filter: invert(100%);
    }
  }
}

@media (max-width: 1300px) {
  .card {
    font-size: 1.6vw;
    
    &:last-child {
      margin-left: auto;
    }
  }

  .button {
    
    .external-link-icon {
      width: 1.7vw;
      height: 1.7vw;
    }
  }
}

@media (max-width: 1100px) {
  .card {
    width: 100%;
    font-size: 2vw;
    
    &:not(:last-of-type) {
      margin-bottom: 2.4em;
    }
  }

  .profile-image {
    height: 15vw;
    width: 15vw;
  }

  .button {
    
    .external-link-icon {
      width: 2.2vw;
      height: 2.2vw;
    }
  }
}

@media (max-width: 900px) {
  .card {
    font-size: 2.4vw;
  }
  
  .profile-image {
    height: 17vw;
    width: 17vw;
  }

  .button {
    
    .external-link-icon {
      width: 2.6vw;
      height: 2.6vw;
    }
  }
}

@media (max-width: 750px) {
  .card {
    font-size: 2.7vw;
  }
  
  .profile-image {
    height: 19vw;
    width: 19vw;
  }

  .button {
    border-width: 2px;
    
    .external-link-icon {
      width: 2.9vw;
      height: 2.9vw;
    }
  }
}

@media (max-width: 550px) {
  .card {
    font-size: 3.3vw;
  }
  
  .profile-image {
    height: 21vw;
    width: 21vw;
  }

  .button {
    
    .external-link-icon {
      width: 18px;
      height: 18px;
    }
  }
}
